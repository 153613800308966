import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import Slide from "@material-ui/core/Slide";
import { get_Id, get_LeftRight } from "../../../../helper/UserData/userdate";
import close from "../../../../static/close.svg";
import { ChooseoOnMapimg, OnChangeNewAddressBookInNewOrderDetails, OnChangeopenAddAddressBookInMenu, SetNewAddressBookInNewOrderDetails, SetopenNewAddressBookInNewOrder } from "../../../../Redux/actions/actionNames";
import { Api_Add_Addressbook_AGW, Api_Get_Addressbook_list_AGW } from "../../../../helper/api/ApiGateway";
import { SucceessFul_i18 } from "../../../../helper/Notification/sendNotification";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import chooseoOnMap from "../../../../static/icon-ic-pin.svg";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { withStyles } from "@material-ui/core/styles";
import Address from "./ffff";
import PhoneInput from "react-phone-number-input";
import { getCountryWithDomain } from "../../../../helper/module";

var _ = require("lodash");


const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#00000",
    }}
  />
));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      code: "",
      address: "",
      adresss_loc: null,
      block: "",
      floor: "",
      unit: "",
      FullName: "",
      PhoneNumber: "",
      loading: false
    };
    this.anchorEl = React.createRef();

  }

  handleCloseNewAddress = () => {
    // this.setState({
    //   openNewAddress: !this.state.openNewAddress,
    // });
    this.props.dispatch(OnChangeopenAddAddressBookInMenu(false))
  };


  validate(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  showPoper = () => {
    this.setState({ showpoper: true });
  };

  closeNewAddressbook = () => {
    this.handleCloseNewAddress()
  };

  OnChangeDetails = (key, value) => {
    this.props.dispatch(
      OnChangeNewAddressBookInNewOrderDetails({ key, value })
    );
  }

  saveAddressBook = () => {
    console.log("saveAddressBook ", this.props.Details)
    if (this.props.Details.title.length > 0) {
      Api_Add_Addressbook_AGW(this.props.dispatch, {
        "title": this.props.Details.title,
        "address": this.props.Details.address,
        "coordinates": [
          this.props.adresss_loc.lng, this.props.adresss_loc.lat
        ],
        "buildingBlock": this.props.Details.block,
        "description": this.props.Details.description,
        "floor": this.props.Details.floor,
        "fullName": this.props.Details.FullName,
        "phone": this.props.Details.PhoneNumber,
        "placeId": "",
        "room": this.props.Details.unit,
        "code": this.props.Details.code,
        email: this.props.Details.email,
        description: this.props.Details.description,
      }, (call) => {
        if (call.loading) {
          this.setState({
            loading: true
          })
        } else {
          this.setState({
            loading: false
          })
          if (call.ok) {
            this.handleCloseNewAddress()
            SucceessFul_i18(
              "Createdsuccessfully",
              "networkError",
              "close",
              () => {
                this.props.dispatch({
                  type: "removeNetworkErrorSnack",
                  key: "networkError",
                });
              },
              new Date().getTime() + Math.random()
            );
            Api_Get_Addressbook_list_AGW(this.props.dispatch, { page: 1, perpage: 20, searchRegex: "" }, false);

          } else {

          }
        }
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.openMenu) {
      this.setState({
        title: "",
        code: "",
        address: "",
        adresss_loc: null,
        block: "",
        floor: "",
        unit: "",
        FullName: "",
        PhoneNumber: "",
        loading: false
      })
    }
  }


  ChooseoOnMapimg = () => {
    this.props.dispatch(
      SetNewAddressBookInNewOrderDetails({
        adresss_loc: null,
      })
    );
    this.props.dispatch(
      ChooseoOnMapimg({
        showMap: true,
        pickupDropOff: {
          type: null,
          id: null,
        },
      })
    );
  };


  render() {
    const { t } = this.props;

    return (
      <Slide
        timeout={650}
        direction={get_LeftRight()}
        in={this.props.openMenu}
        mountOnEnter
        unmountOnExit
      >
        <div className="NewAddressbookConatiner">
          <nRHeader style={{ boxShadow: "none" }}>
            <img
              onClick={() => this.closeNewAddressbook()}
              className="close"
              src={close}
            />
            <span className="title">
              <Trans i18nKey={"newAddress"}> </Trans>
            </span>
            {!isDesktop && !isTablet &&
              <span className="ChooseoOnMap ChooseoOnMAPNewAddressBook" onClick={this.ChooseoOnMapimg}>
                <img src={chooseoOnMap} className="ChooseoOnMapimg" />
                <span className="ChooseoOnMapText">
                  {" "}
                  <Trans i18nKey={"ChooseoOnMap"}> </Trans>{" "}
                </span>
              </span>
            }
          </nRHeader>


          <span className="NewAddressbookInfoTitle">
            <Trans i18nKey={"NewAddressbookInfoTitle"}> </Trans>
          </span>

          <input value={this.props.Details.title} placeholder={t("Title")} className="NewAddressbookInfoTitleField" onChange={(e) => {
            this.OnChangeDetails("title", e.target.value);
          }} />
          <input
            value={this.props.Details.code}
            placeholder={t("Code")}
            onChange={(e) => {
              this.OnChangeDetails("code", e.target.value);
            }}
            className="NewAddressbookInfoCodeField"
          />

          <hr className="NewAddressbookInfohr" />

          <span className="NewAddressbookAddressdetailsTitle">
            <Trans i18nKey={"Addressdetails"}> </Trans>
          </span>

          {/* <input
          type={"text"}
          ref={this.anchorEl}
          list="ice-cream-flavors"
          autocomplete="new-password"
            value={this.props.Details.address}
            className="NewAddressbookAddressdetailsAddress"
            placeholder={t("address")}
            onChange={(e) => {
              this.OnChangeDetails("address", e.target.value);
            }}
          /> */}

          <Address />


          <div className="NewAddressbookAddressdetailsFieldsContainer pickupcollapsecontainer ">
            <input value={this.props.Details.block} className="blockPickup" placeholder={t("block")} onChange={(e) => {
              this.OnChangeDetails("block", e.target.value);
            }} />
            <input value={this.props.Details.floor} className="floorPickup" placeholder={t("Floor")} onChange={(e) => {
              this.OnChangeDetails("floor", e.target.value);
            }} />
            <input value={this.props.Details.unit} className="UnitPickup" placeholder={t("Unit")} onChange={(e) => {
              this.OnChangeDetails("unit", e.target.value);
            }} />
            <input
              value={this.props.Details.FullName}
              className="senderfullnamePickup"
              placeholder={t("senderfullname")}
              onChange={(e) => {
                this.OnChangeDetails("FullName", e.target.value);
              }}
            />
               {/* <PhoneInput
                defaultCountry={global.config.Country}
                placeholder={t("senderphonenumber")}
                value={this.props.Details.PhoneNumber}
                onChange={(e) => {
                  this.OnChangeDetails("PhoneNumber", e);
                }}
                
                id="senderPhoneNumber"
                className="senderphonenumberPickup"
              /> */}

             <input
              value={this.props.Details.PhoneNumber}
              className="senderphonenumberPickup"
              placeholder={t("senderphonenumber")}
              onChange={(e) => {
                this.OnChangeDetails("PhoneNumber", e.target.value);
              }}
            /> 
          </div>          
          <input
            value={this.props.Details.email}
            className="NewAddressbookAddressdetailsAddress"
            placeholder={t("email")}
            onChange={(e) => {
              this.OnChangeDetails("email", e.target.value);
            }}
          />
          <input
            value={this.props.Details.description}
            className="NewAddressbookAddressdetailsAddress"
            placeholder={t("notesfordriver")}
            onChange={(e) => {
              this.OnChangeDetails("description", e.target.value);
            }}
          />



          <div className="NewAddressbookFooter">
            <span
              onClick={(this.props.Details.title.length > 0  && this.props.Details.address.length > 0 && this.props.adresss_loc != null && !this.props.Details.loading && (this.props.Details.email.length > 0 ? this.validate(this.props.Details.email) : true)) ? this.saveAddressBook : null}
              className={`SettingFooterButton ${
                (this.props.Details.title.length == 0 || this.props.Details.address.length == 0 || this.props.adresss_loc == null || (this.props.Details.email.length > 0 ? !this.validate(this.props.Details.email) : false)) && "DeactiveSettingFooterButton"

                }`}

                style={{fontSize:"17px",height:"auto",padding:"8px 25px",marginTop:"10px"}}
            >
              <Trans i18nKey={"Save"}></Trans>
            </span>
          </div>



        </div>

      </Slide>
    );
  }
}

const mapStateToProps = (state) => ({
  openMenu: state.Addressbook.openAddAddressBookInMenu,
  Details: state.Addressbook.NewAddressBookInNewOrderDetails,
  adresss_loc: state.Addressbook.NewAddressBookInNewOrderDetails.adresss_loc,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
